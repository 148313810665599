import React from "react";
import { Col, Spinner, Container, Row } from "react-bootstrap";

function Loadingpart() {
	return (
		<Container className="loading-container">
			<Row>
				<Col className="align-center">
					<Spinner animation="border" role="status">
						<span className="visually-hidden-focusable">Loading...</span>
					</Spinner>
				</Col>
			</Row>
		</Container>
	);
}

export default Loadingpart;
