import React, { lazy, Suspense } from "react";
import "./style/App.scss";
import "./fontello/css/fontello.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { AppInsightsContext } from "@microsoft/applicationinsights-react-js";
import { reactPlugin } from "./components/Tracking/ApplicationInsights";
import Loadingpart from "./components/Loadingpart.js";
const OrganizationList = lazy(() => import("./components/OrganizationList"));
const OrganizationDetail = lazy(() =>
	import("./components/OrganizationDetail")
);
const RedirectToWill = lazy(() => import("./components/RedirectToWill"));
const FileUpload = lazy(() => import("./components/FileUpload"));
const Header = lazy(() => import("./components/Header"));
const Footer = lazy(() => import("./components/Footer"));
const InfoHeader = lazy(() => import("./components/InfoHeader"));
// const TawkTo = lazy(() => import("./components/TawkTo"));

function App() {
	return (
		<AppInsightsContext.Provider value={reactPlugin}>
			<Router>
				<main className="flex-shrink-0">
					<Suspense fallback={null}>
						<InfoHeader />
						<Header />
					</Suspense>
					<Suspense fallback={<Loadingpart />}>
						<Routes>
							<Route exact path="/" element={<OrganizationList />} />
							<Route
								exact
								path="/organisasjon/:orgId"
								element={<OrganizationDetail />}
							/>
							<Route
								path="/organisasjon/:orgId/testament"
								element={<RedirectToWill />}
							/>
							<Route path="/filer" element={<FileUpload />} />
						</Routes>
					</Suspense>
				</main>
				<Suspense fallback={null}>
					<Footer />
					{/* <TawkTo /> */}
				</Suspense>
			</Router>
		</AppInsightsContext.Provider>
	);
}

export default App;
