import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import { ReactPlugin } from "@microsoft/applicationinsights-react-js";
import { createBrowserHistory } from "history";

const browserHistory = createBrowserHistory({ basename: "" });
const reactPlugin = new ReactPlugin();
const appInsights = new ApplicationInsights({
	config: {
		instrumentationKey: "25dc8709-3859-47ad-8c8b-44fc994b0c54",
		extensions: [reactPlugin],
		extensionConfig: {
			[reactPlugin.identifier]: { history: browserHistory },
		},
	},
});
appInsights.loadAppInsights();
export { reactPlugin, appInsights };
